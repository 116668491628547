import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	LinearProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../utils/POContext';
import { Column, Line, RegularPanel } from '../../../styles';
import Panel from '../../../components/Panel';

interface Props {
	data: any;
	open: boolean;
	close: () => void;
	loading: boolean;
	progress: number;
	buffer: number;
	successIDs: string[];
	failedIDs: string[];
}

const SubmitedResumeDialog: React.FC<Props> = ({
	data,
	open,
	close,
	loading,
	progress,
	buffer,
	successIDs,
	failedIDs,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const handleClose = () => {
		close();
	};

	const renderDialogTitle = () => (
		<Typography
			variant="h6"
			style={{
				color: selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
			}}
		>
			{t('Autores.Resultados dos envios')}
		</Typography>
	);

	const renderGoodResults = () => (
		<Column style={{ width: '100%', alignItems: 'flex-start' }}>
			{successIDs.length > 0 && (
				<>
					<Typography
						variant="caption"
						sx={{
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Autores.Sucesso para IDs')}: {successIDs.join(', ')}
					</Typography>
					<Typography variant="caption" sx={{ color: selectedTheme.success }}>
						{t('Autores.Motivo')}:
						<br />
						{data.successDetails.map((detail, index) => (
							<span key={index}>
								{detail}
								<br />
							</span>
						))}
					</Typography>
				</>
			)}
		</Column>
	);

	const renderNotGoodResults = () => (
		<Column style={{ width: '100%', alignItems: 'flex-start' }}>
			{failedIDs.length > 0 && (
				<>
					<Typography
						variant="caption"
						sx={{
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Autores.Falha para IDs')}: {failedIDs.join(', ')}
					</Typography>
					<Typography variant="caption" sx={{ color: selectedTheme.error }}>
						{t('Autores.Motivo')}:
						<br />
						{data.failureDetails.map((detail, index) => (
							<span key={index}>
								{detail}
								<br />
							</span>
						))}
					</Typography>
				</>
			)}
		</Column>
	);

	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			onClose={(_, reason) => {
				if (reason !== 'backdropClick') {
					handleClose();
				}
			}}
			open={open}
		>
			<DialogTitle
				style={{
					borderBottomColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
					padding: '12px 20px',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					display: 'flex',
				}}
			>
				{renderDialogTitle()}
			</DialogTitle>

			<DialogContent style={{ padding: 0 }}>
				<Column
					style={{
						alignItems: 'center',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					}}
				>
					{loading ? (
						<>
							<LinearProgress
								variant="determinate"
								value={progress}
								valueBuffer={buffer}
								sx={{ width: '100%', marginTop: 2 }}
							/>
							<Typography
								variant="caption"
								sx={{
									marginTop: 2,
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{t('Autores.Progresso')}: {progress.toFixed(2)}%
							</Typography>
						</>
					) : (
						<RegularPanel
							style={{
								background:
									selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
								width: '100%',
							}}
						>
							<Panel
								title={t('Autores.Resumo da Atualização')}
								noMargin
								contentPadding="10px 20px"
								open
							>
								{successIDs.length > 0 ? renderGoodResults() : null}

								{failedIDs.length > 0 ? renderNotGoodResults() : null}
							</Panel>
						</RegularPanel>
					)}
				</Column>
			</DialogContent>

			<DialogActions
				style={{
					borderTop: 'solid',
					borderTopWidth: 1,
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.overlay3dp
							: selectedTheme.foreground,
				}}
			>
				<Line
					style={{
						justifyContent: 'end',
						margin: '2px 10px 0px 10px',
						columnGap: 20,
					}}
				>
					<Button
						size="small"
						onClick={handleClose}
						style={{
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.tone === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.primaryDark
									: selectedTheme.primaryDark,
							width: 'auto',
						}}
					>
						{t('Autores.Fechar')}
					</Button>
				</Line>
			</DialogActions>
		</Dialog>
	);
};

export default SubmitedResumeDialog;
