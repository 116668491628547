import React, { ReactChild, useEffect, useState } from 'react';
import { parse, isValid } from 'date-fns';
import { makeStyles, withStyles } from '@mui/styles';
import {
	Card,
	Divider,
	Typography,
	Paper,
	TableBody,
	TableContainer,
	TableSortLabel,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TablePagination,
	Box,
	Checkbox,
	Zoom,
	IconButton,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { Primary, Footer, Line, Header } from '../../styles';
import { api, setupRequestToken } from '../../utils/api';
import { useAuth0 } from '../../react-auth0-spa';
import TableSearch from '../TableSearch';
import { usePO } from '../../utils/POContext';
import '../../i18n';
import { OfferSwitch, OfferToolTip } from '../../helpers';
import DataTypes from './DataTypes';
import { useDispatch, useSelector } from 'react-redux';
import { changeRowPerPage } from '../../redux/slices/rowPerPageSlice';
import {
	openPanel,
	closePanel,
	closeAllPanels,
} from '../../redux/slices/expansibleTableSlice';
import { useSnackbar } from 'notistack';
import BackDropLoading from '../BackdropLoading';
import { HandleGetThemeColor } from '../../helpers';
import ExpansibleTableRow from './ExpansibleTableRow';
import { useHotkeys } from 'react-hotkeys-hook';
import Skeleton from 'react-loading-skeleton';

interface Props {
	colsData: any;
	tableData: any;

	ActionComponent1?: any;
	ActionComponent1Props?: any;
	ActionComponent2?: any;
	ActionComponent2Props?: any;
	ActionComponent3?: any;
	ActionComponent3Props?: any;
	ActionComponent4?: any;
	ActionComponent4Props?: any;
	backdropLoading?: boolean;
	checkboxes?: boolean;
	children?: any;
	clickableRows?: boolean;
	customCurrentPage?: number;
	customInfoButton?: React.ReactNode;
	CustomHeaderComponent?: any;
	CustomHeaderComponentProps?: any;
	customlabelRowsPerPage?: string;
	customLeftFooterComponent?: any;
	customNoDataMessage?: string | React.ReactNode;
	customRightFooterComponent?: any;
	customRowsPerPage?: any;
	customRowsPerPageOptions?: any;
	customSearch?: ReactChild;
	customTableLength?: number;
	customTablePagination?: any;
	dataFromSwitch?: (data) => void;
	denseText?: boolean;
	disableSortableColumn?: boolean;
	expandedColsData?: any;
	expandedDataFetch?: (data) => unknown;
	expandedNoDataMessage?: any;
	expandedTableActions?: any;
	expandedTableData?: any;
	expansibleRows?: boolean;
	handleChangeCustomRowsPerPage?: (data) => void;
	loading?: boolean;
	nestedTable?: boolean;
	noShadow?: boolean;
	noTheme?: boolean;
	onSwitchChange?: any;
	selectAll?: boolean;
	selectedTableItems?: any;
	setSelectedTableItems?: (data) => void;
	switches?: boolean;
	switchesToolTip?: any;
	tableActions?: any;
	tableFooter?: boolean;
	tableFooterButton?: any;
	tableKey?: string;
	ternaryCheckbox?: boolean;
	userPermission?: boolean;
	windowHeader?: boolean;
	windowTitle?: string;
	tableWidth?: string;
}
/**
 * @component OFFER TABLE
 *
 * @description Designed to tabulate, show and manipulate data on an
 * objects array.
 *
 * @API https://docs.offertech.com.br/doc/offertable-JWGtdynk1t
 *
 * @tableData Input data must be an array of objects, each containing the
 * information to be shown on a table line.
 *
 * @colsData A second objects array must be provided on a specific format
 * (as described on the API documentation) to define the conumn configuration
 * and also wich data fields will be extracted from the main object (tableData).
 */
const OfferTable: React.FC<Props> = ({
	colsData,
	tableData,

	ActionComponent1,
	ActionComponent1Props,
	ActionComponent2,
	ActionComponent2Props,
	ActionComponent3,
	ActionComponent3Props,
	ActionComponent4,
	ActionComponent4Props,
	backdropLoading,
	checkboxes,
	clickableRows,
	customCurrentPage,
	customInfoButton,
	CustomHeaderComponent,
	CustomHeaderComponentProps,
	customlabelRowsPerPage,
	customLeftFooterComponent,
	customNoDataMessage,
	customRightFooterComponent,
	customRowsPerPage,
	customRowsPerPageOptions,
	customSearch,
	customTableLength,
	customTablePagination,
	dataFromSwitch,
	denseText,
	disableSortableColumn,
	expandedColsData,
	expandedDataFetch,
	expandedNoDataMessage,
	expandedTableActions,
	expandedTableData,
	expansibleRows,
	handleChangeCustomRowsPerPage,
	loading,
	nestedTable,
	noShadow,
	noTheme = false,
	onSwitchChange,
	selectAll,
	selectedTableItems,
	setSelectedTableItems,
	switches,
	switchesToolTip,
	tableActions,
	tableFooter,
	tableFooterButton,
	tableKey,
	ternaryCheckbox,
	userPermission,
	windowHeader,
	windowTitle,
	tableWidth,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const { token } = useAuth0();
	setupRequestToken(api, token);

	useHotkeys('ctrl+alt+a', () => handleKeyShortcutSelectAll());
	/*
	Here are the dispatch functions and useSelector to select rows per page,
	using the redux dispatch
	*/
	const dispatch = useDispatch();
	const rowsPerPage = useSelector(
		(state: any) => state.rowPerPage.selectedRowPerPage
	);

	/**
	 * Table Styling
	 */
	const useStyles = makeStyles(() => ({
		root: {
			width: tableWidth || '100%',
			display: 'flex',
			flexDirection: 'column',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 0,
			borderRadius: windowHeader || noShadow ? 5 : 0,
			border:
				noShadow && selectedTheme.id !== 'dark'
					? `1px solid ${selectedTheme.borderLight}`
					: 'none',
			boxShadow:
				nestedTable || noShadow ? 'none' : '4px 4px 10px rgba(0, 0, 0, 0.1)',
		},
		tablePaper: {
			width: tableWidth || '100%',
		},
		tableCell: {
			color: HandleGetThemeColor(
				{
					themeID: 'dark',
					primaryThemeColor: selectedTheme.textColorMedium,
					alternateThemeColor: '#202020',
				},
				{ noTheme: noTheme, noThemeColor: '#202020' }
			),
			'&:hover': {
				color: HandleGetThemeColor(
					{
						themeID: 'dark',
						primaryThemeColor: selectedTheme.textColorHigh,
						alternateThemeColor: '#737373',
					},
					{ noTheme: noTheme, noThemeColor: '#737373' }
				),
			},
		},
		header: {
			justifyContent: 'space-between',
			width: tableWidth || '100%',
			height: 60,
			color: 'white',
			fontSize: 18,
			fontWeight: 'bold',
			padding: 20,
		},
		title: {
			color: Primary,
			fontWeight: 'bold',
		},
		body: {
			color: Primary,
		},
		container: {
			maxHeight: '100%', // STICKY HEADER
		},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
		selectDropdown: {
			color: HandleGetThemeColor(
				{
					themeID: 'dark',
					primaryThemeColor: selectedTheme.textColorHigh,
					alternateThemeColor: selectedTheme.disableBackground,
				},
				{ noTheme: noTheme, noThemeColor: '#000' }
			),
			backgroundColor: HandleGetThemeColor(
				{
					themeID: 'dark',
					primaryThemeColor: selectedTheme.overlay4dp,
					alternateThemeColor: selectedTheme.disableBackground,
				},
				{ noTheme: noTheme, noThemeColor: '#fff' }
			),
		},
		rootPagination: {
			'&:focus': {
				backgroundColor: HandleGetThemeColor(
					{
						themeID: 'dark',
						primaryThemeColor: selectedTheme.overlay3dp,
						alternateThemeColor: selectedTheme.foreground,
					},
					{ noTheme: noTheme, noThemeColor: '#fff' }
				),
			},
		},
		menuItem: {
			'&:hover': {
				backgroundColor: HandleGetThemeColor(
					{
						themeID: 'dark',
						primaryThemeColor: selectedTheme.overlay8dp,
						alternateThemeColor: selectedTheme.disableBackground,
					},
					{ noTheme: noTheme, noThemeColor: '#aaaaaa' }
				),
			},
		},
		paginationSelectIcon: {
			color: HandleGetThemeColor({
				themeID: 'dark',
				primaryThemeColor: selectedTheme.textColorHigh,
				alternateThemeColor: selectedTheme.disableBackground,
			}),
		},
	}));

	const classes = useStyles();

	const handleKeyShortcutSelectAll = () => {
		if (selectedRows.length || filteredTableData.length !== 0) {
			handleSelectAllClick();
		} else {
			enqueueSnackbar(t('Nenhum item selecionado'), {
				variant: 'info',
			});
		}
	};

	// Check page height and adjust rows per page
	const rowsPerPageHeight =
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1;

	const switchOfferStyles = (ISactive) => {
		if (ISactive) {
			return HandleGetThemeColor(
				{
					themeID: 'dark',
					primaryThemeColor: selectedTheme.textColorHigh,
					alternateThemeColor: selectedTheme.primary,
				},
				{ noTheme: noTheme, noThemeColor: '#272E67' }
			);
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.primaryDark
				: selectedTheme.disabled;
		}
	};

	const typographyStyleHeader = () => {
		if (noTheme) {
			return '#272E67';
		} else {
			switch (selectedTheme.id) {
				case 'main':
					return selectedTheme.primary;
				case 'dark':
					return selectedTheme.textColorHigh;
				default:
					return selectedTheme.foreground;
			}
		}
	};

	const openPanels = useSelector(
		(state: any) => state.expansibleTable.openPanels
	);

	/**
	 * State Variables
	 */
	let sortedData = tableData;

	const [page, setPage] = useState(0);

	const [order, setOrder] = React.useState<Order>(null);
	const [orderBy, setOrderBy] = useState<any>('name');
	const [selectedRows, setSelectedRows] = useState<any[]>(
		selectedTableItems || []
	);

	const [selectionState, setSelectionState] = useState<number>(2);
	// TableSearch component
	const [searchText, setSearchText] = useState('');

	/** Creates and control accordion panels opening triggers using Redux */
	const handleOpenRowPanel = (id) => {
		if (openPanels.includes(id)) {
			dispatch(closePanel(id));
		} else {
			dispatch(openPanel(id));
		}
	};

	useEffect(() => {
		if (tableData.length !== filteredTableData.length) {
			setPage(0);
		}
		if (selectedTableItems) {
			setSelectedRows(selectedTableItems);
		}
	}, [tableData.length, selectedTableItems]);

	// Table search function
	const filteredTableData =
		tableData &&
		tableData.filter((data) => {
			if (searchText === '') return true;
			return colsData.some((col) =>
				data[col.fieldName]
					?.toString()
					.toLowerCase()
					.includes(searchText.toLowerCase())
			);
		});

	// Constants
	const actionTranslate = t('Ações');
	const noDataTOvisualize = t('Não há dados a exibir');

	// Handle functions
	const handleSendDataSwitch = (data: any) => {
		if (dataFromSwitch) {
			dataFromSwitch(data);
		}
	};

	/** Table items selection by checkbox */
	const handleSelectAllClick = () => {
		// Cycles through selection states ('none', 'visible' and 'whole table content')
		const cycles = ternaryCheckbox ? 3 : 2;

		// Normal cycling
		if (selectionState < cycles) {
			setSelectionState(selectionState + 1);
		} else {
			setSelectionState(1);
		}

		switch (selectionState) {
			case 1:
				// Select none
				setSelectedRows([]);
				if (setSelectedTableItems) {
					setSelectedTableItems([]);
				}
				enqueueSnackbar(t('Nenhum item selecionado'), {
					variant: 'info',
				});
				break;
			case 2:
				// Select visible
				if (selectedRows.length < sortedData.length) {
					setSelectedRows(
						sortedData.slice(
							page * (customRowsPerPage || rowsPerPage),
							page * (customRowsPerPage || rowsPerPage) +
								(customRowsPerPage || rowsPerPage)
						)
					);
					if (setSelectedTableItems) {
						setSelectedTableItems(
							sortedData.slice(
								page * (customRowsPerPage || rowsPerPage),
								page * (customRowsPerPage || rowsPerPage) +
									(customRowsPerPage || rowsPerPage)
							)
						);
					}
				}
				enqueueSnackbar(t('Somente itens visíveis adicionados à seleção'), {
					variant: 'info',
				});
				break;
			case 3:
				// Select all
				if (filteredTableData.length > 0) {
					setSelectedRows(filteredTableData);
					if (setSelectedTableItems) {
						setSelectedTableItems(filteredTableData);
					}
				}
				enqueueSnackbar(t('Todos os ítens da tabela adicionados à seleção'), {
					variant: 'info',
				});
				break;
			default:
				break;
		}
	};

	const handleSelectClick = (data: any) => {
		// data set containing the selected rows
		const tempSelectedRows = new Set(selectedRows);
		if (tempSelectedRows.has(data)) {
			tempSelectedRows.delete(data);
		} else {
			tempSelectedRows.add(data);
		}
		setSelectedRows(Array.from(tempSelectedRows));
		if (setSelectedTableItems) {
			setSelectedTableItems(Array.from(tempSelectedRows));
		}
	};

	const isSelected = (row) => selectedRows.findIndex((e) => e === row) !== -1;

	/**
	 * Table header sorting
	 */

	function isDateString(value: string): boolean {
		const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
		return datePattern.test(value);
	}

	function isCurrencyString(value: string): boolean {
		const currencyPattern = /^[\d,.]+$/;
		return currencyPattern.test(value);
	}

	function parseDate(dateStr: string): Date | null {
		const parsedDate = parse(dateStr, 'dd/MM/yyyy', new Date());

		if (!isValid(parsedDate)) {
			return null;
		}
		return parsedDate;
	}

	function parseCurrency(currencyStr: string): number | null {
		const normalized = currencyStr.replace(/[.,]/g, '');
		const value = parseFloat(normalized);
		return isNaN(value) ? null : value;
	}

	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		const aValue = a[orderBy];
		const bValue = b[orderBy];

		if (typeof aValue === 'string' && typeof bValue === 'string') {
			if (isDateString(aValue) && isDateString(bValue)) {
				const dateA = parseDate(aValue);
				const dateB = parseDate(bValue);

				if (dateA && dateB) {
					return dateB.getTime() - dateA.getTime();
				}
			}
			if (isCurrencyString(aValue) && isCurrencyString(bValue)) {
				const currencyA = parseCurrency(aValue);
				const currencyB = parseCurrency(bValue);

				if (currencyA !== null && currencyB !== null) {
					return currencyB - currencyA;
				}
			}
		}

		if (bValue < aValue) return -1;
		if (bValue > aValue) return 1;
		return 0;
	}

	type Order = 'asc' | 'desc' | null;

	function getComparator<Key extends keyof any>(
		order: Order,
		orderBy: Key
	): (
		a: { [key in Key]: number | string },
		b: { [key in Key]: number | string }
	) => number {
		if (order === null || orderBy === null) {
			return () => 0;
		}

		return order === 'desc'
			? (a, b) => {
					return descendingComparator(a, b, orderBy);
				}
			: (a, b) => {
					return -descendingComparator(a, b, orderBy);
				};
	}

	function stableSort<T>(
		array: readonly T[],
		comparator: (a: T, b: T) => number
	) {
		if (comparator === (() => 0)) {
			return array;
		}

		const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});

		return stabilizedThis.map((el) => el[0]);
	}

	/**
	 * Table sorting and selecting functions
	 */
	interface EnhancedTableProps {
		classes: any;
		numSelected: number;
		onRequestSort: (event: React.MouseEvent<any>, property: any) => void;
		onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
		order: Order;
		orderBy: string;
		rowCount: number;
	}

	function EnhancedTableHead(props: EnhancedTableProps) {
		const { onRequestSort } = props;

		const createSortHandler =
			(property: any) => (event: React.MouseEvent<any>) => {
				onRequestSort(event, property);
			};

		/**
		 * Table Header output
		 */
		return (
			<TableHead>
				<TableRow>
					{expansibleRows && (
						<TableCell
							key="expansible"
							padding="checkbox"
							style={{
								background: HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme.overlay4dp,
										alternateThemeColor: selectedTheme.disableBackground,
									},
									{ noTheme: noTheme, noThemeColor: '#fafafa' }
								),
								borderBottom: `1px solid ${HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme.footerLine,
										alternateThemeColor: '#e0e0e0',
									},
									{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
								)}`,
							}}
						/>
					)}
					{selectAll && (checkboxes || clickableRows) ? (
						<TableCell
							key="checkbox"
							padding="checkbox"
							style={{
								paddingLeft: 25,
								color: HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme.textColorHigh,
										alternateThemeColor: selectedTheme.primary,
									},
									{ noTheme: noTheme, noThemeColor: '#272E67' }
								),
								borderBottom: `1px solid ${HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme.footerLine,
										alternateThemeColor: '#e0e0e0',
									},
									{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
								)}`,
								background:
									selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
							}}
						>
							<Checkbox
								size="small"
								color="primary"
								indeterminate={
									selectedRows.length > 0 &&
									selectedRows.length < filteredTableData.length
								}
								checked={
									selectedRows.length > 0 &&
									selectedRows.length === filteredTableData.length
								}
								onChange={handleSelectAllClick}
								inputProps={{
									'aria-label': `${t('selecionar todos os itens')}`,
								}}
								style={{
									color:
										selectedTheme?.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.primary,
								}}
							/>
						</TableCell>
					) : (
						<TableCell
							padding="none"
							style={{
								padding: checkboxes ? '0 0 0 35px' : 0,
								background: HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme.overlay4dp,
										alternateThemeColor: selectedTheme.disableBackground,
									},
									{ noTheme: noTheme, noThemeColor: '#fafafa' }
								),
								borderBottom: `1px solid ${HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme.footerLine,
										alternateThemeColor: '#e0e0e0',
									},
									{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
								)}`,
							}}
						/>
					)}
					{colsData.map((headCell: any, index: React.Key) => (
						<TableCell
							key={`tableCell${index}`}
							align={headCell.alignTitle}
							sortDirection={
								orderBy === headCell.columnTitle ? order || false : false
							}
							style={{
								width: headCell.widthFixed || 'auto',
								// maxWidth: headCell.cellWidth,
								padding: '0 0 0 -25px',
								background: HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme.overlay4dp,
										alternateThemeColor: selectedTheme.disableBackground,
									},
									{ noTheme: noTheme, noThemeColor: '#fafafa' }
								),
								color: HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme.textColorMedium,
										alternateThemeColor: '#000000de',
									},
									{ noTheme: noTheme, noThemeColor: '#000000de' }
								),
								borderBottom: `1px solid ${HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme.footerLine,
										alternateThemeColor: '#e0e0e0',
									},
									{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
								)}`,
							}}
						>
							<OfferToolTip
								title={headCell.columnTitle}
								aria-label="inativo"
								enterDelay={700}
								enterNextDelay={700}
								arrow
								TransitionComponent={Zoom}
							>
								{disableSortableColumn ? (
									<Typography
										data-test-id={headCell.columnTitle}
										noWrap
										variant={denseText ? 'caption' : 'subtitle2'}
										className={classes.tableCell}
										style={{
											fontWeight: headCell.columnTitleBold || 'bold',
											alignItems: 'end',
											color: HandleGetThemeColor(
												{
													themeID: 'dark',
													primaryThemeColor: selectedTheme.textColorMedium,
													alternateThemeColor: '#000000de',
												},
												{ noTheme: noTheme, noThemeColor: '#000000de' }
											),
											textOverflow: 'ellipsis',
										}}
									>
										{headCell.columnTitle}
									</Typography>
								) : (
									<TableSortLabel
										active={orderBy === headCell.fieldName}
										direction={
											orderBy === headCell.fieldName
												? order || 'asc'
												: undefined
										}
										onClick={createSortHandler(headCell.fieldName)}
										style={{
											margin: headCell.marginTitle,
										}}
										sx={{
											'&.Mui-active': {
												'.MuiTableSortLabel-icon': {
													color: HandleGetThemeColor(
														{
															themeID: 'dark',
															primaryThemeColor: selectedTheme.textColorMedium,
															alternateThemeColor: '##29292999',
														},
														{ noTheme: noTheme, noThemeColor: '##29292999' }
													),
												},
											},
											'.MuiTableSortLabel-icon': {
												color: HandleGetThemeColor(
													{
														themeID: 'dark',
														primaryThemeColor: selectedTheme.textColorDisable,
														alternateThemeColor: '#00000099',
													},
													{ noTheme: noTheme, noThemeColor: '#00000099' }
												),
											},
										}}
									>
										<Typography
											data-test-id={headCell.columnTitle}
											noWrap
											variant={denseText ? 'caption' : 'subtitle2'}
											className={classes.tableCell}
											style={{
												fontWeight: headCell.columnTitleBold || 'bold',
												alignItems: 'end',
												color: HandleGetThemeColor(
													{
														themeID: 'dark',
														primaryThemeColor: selectedTheme.textColorMedium,
														alternateThemeColor: '#000000de',
													},
													{ noTheme: noTheme, noThemeColor: '#000000de' }
												),
											}}
										>
											{headCell.columnTitle}
										</Typography>
										{orderBy === headCell.columnTitle ? (
											<span className={`${classes.visuallyHidden} `}>
												{order === 'desc' && order !== null
													? 'sorted descending'
													: 'sorted ascending'}
											</span>
										) : null}
									</TableSortLabel>
								)}
							</OfferToolTip>
						</TableCell>
					))}
					{tableActions ? (
						<TableCell
							key="actions"
							align="center"
							style={{
								background:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay4dp
										: selectedTheme.disableBackground,
								borderBottom: `1px solid ${HandleGetThemeColor(
									{
										themeID: 'dark',
										primaryThemeColor: selectedTheme.footerLine,
										alternateThemeColor: '#e0e0e0',
									},
									{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
								)}`,
							}}
						>
							<Typography
								noWrap
								variant={denseText ? 'caption' : 'subtitle2'}
								style={{
									marginLeft: -30,
									fontWeight: 'bold',
									alignItems: 'end',
									color: HandleGetThemeColor(
										{
											themeID: 'dark',
											primaryThemeColor: selectedTheme.textColorMedium,
											alternateThemeColor: '#000000de',
										},
										{ noTheme: noTheme, noThemeColor: '#000000de' }
									),
								}}
							>
								{actionTranslate}
							</Typography>
						</TableCell>
					) : null}
				</TableRow>
			</TableHead>
		);
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === 'asc';
		if (order === null) {
			setOrder('asc');
		} else if (isAsc) {
			setOrder('desc');
		} else if (order === 'desc') {
			setOrder(null);
			setOrderBy(null);
		} else {
			setOrder('asc');
		}
		if (order !== 'desc') {
			setOrderBy(property);
		}
	};

	const handleChangePage = (event, newPage) => {
		dispatch(closeAllPanels());
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(changeRowPerPage(parseInt(event.target.value, 10)));
		setPage(0);
	};

	const preventDefault = () => {
		return null;
	};

	const PaginationTheme = withStyles({
		selectIcon: {
			color: HandleGetThemeColor(
				{
					themeID: 'dark',
					primaryThemeColor: selectedTheme.textColorHigh,
					alternateThemeColor: selectedTheme.disableBackground,
				},
				{ noTheme: noTheme, noThemeColor: '#0000008a' }
			),
		},
		menuItem: {
			'&:hover': {
				backgroundColor: HandleGetThemeColor(
					{
						themeID: 'dark',
						primaryThemeColor: selectedTheme.overlay8dp,
						alternateThemeColor: selectedTheme.disableBackground,
					},
					{ noTheme: noTheme, noThemeColor: '#0000000a' }
				),
			},
		},
		select: {
			'&:focus': {
				backgroundColor: HandleGetThemeColor(
					{
						themeID: 'dark',
						primaryThemeColor: selectedTheme.overlay3dp,
						alternateThemeColor: selectedTheme.foreground,
					},
					{ noTheme: noTheme, noThemeColor: '#fff' }
				),
			},
		},
		actions: {
			'& .Mui-disabled': {
				color: noTheme ? '#00000042' : '',
			},
		},
	})(TablePagination);

	const renderRegularPagination = () => {
		if (loading) {
			return null;
		}

		const isPaginationRequired =
			tableData.length > (customRowsPerPage || rowsPerPageHeight) ||
			(customTableLength !== undefined &&
				customTableLength > (customRowsPerPage || rowsPerPageHeight));

		return isPaginationRequired ? (
			<PaginationTheme
				ActionsComponent={customTablePagination || undefined}
				rowsPerPageOptions={
					customRowsPerPageOptions || [rowsPerPageHeight, 25, 50, 100]
				}
				count={customTableLength || tableData.length}
				rowsPerPage={customRowsPerPage || rowsPerPage}
				page={customCurrentPage || page}
				onPageChange={handleChangePage}
				labelRowsPerPage={customlabelRowsPerPage || t('Linhas por página')}
				onRowsPerPageChange={
					handleChangeCustomRowsPerPage || handleChangeRowsPerPage
				}
				slotProps={{
					select: {
						MenuProps: { classes: { paper: classes.selectDropdown } },
					},
				}}
				style={{
					color: HandleGetThemeColor(
						{
							themeID: 'dark',
							primaryThemeColor: selectedTheme.textColorHigh,
							alternateThemeColor: selectedTheme.disableBackground,
						},
						{ noTheme: noTheme, noThemeColor: '#202020' }
					),
					borderBottom: 'none',
				}}
			/>
		) : null;
	};

	const renderFooter = () =>
		tableFooter ? (
			<Footer
				style={{
					borderTopColor: HandleGetThemeColor(
						{
							themeID: 'dark',
							primaryThemeColor: selectedTheme?.footerLine,
							alternateThemeColor: '',
						},
						{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
					),
					background: HandleGetThemeColor(
						{
							themeID: 'dark',
							primaryThemeColor: selectedTheme?.overlay3dp,
							alternateThemeColor: '',
						},
						{ noTheme: noTheme, noThemeColor: '#fff' }
					),
					color: noTheme ? '#000000de' : '',
				}}
			>
				<Line
					style={{
						width: 'auto',
						justifyContent: 'space-between',
					}}
				>
					<Line>
						{customLeftFooterComponent || null}
						{customSearch || (
							<TableSearch setSearchText={setSearchText} noTheme={noTheme} />
						)}
					</Line>

					<div style={{ marginLeft: '20%' }}>
						{customRightFooterComponent || null}
					</div>
				</Line>
				<Line
					style={{
						width: 'auto',
						justifyContent: 'flex-end',
					}}
				>
					{customInfoButton || null}
					{renderRegularPagination()}
					{tableFooterButton || null}
				</Line>
			</Footer>
		) : null;

	const renderHeaderLine = () => {
		if (windowHeader) {
			return (
				<>
					<Divider
						style={{
							background: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.footerLine,
									alternateThemeColor: '',
								},
								{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
							),
						}}
					/>
					<Divider
						style={{
							background: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.footerLine,
									alternateThemeColor: '',
								},
								{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
							),
						}}
					/>
				</>
			);
		}
		return <div />;
	};

	const renderHeader = () => {
		if (windowHeader) {
			return (
				<Header
					data-testid="headerText"
					style={{
						background: HandleGetThemeColor(
							{
								themeID: 'dark',
								primaryThemeColor: selectedTheme.vertMenuColor,
								alternateThemeColor: selectedTheme.gradient,
							},
							{ noTheme: noTheme, noThemeColor: '#DCDB00' }
						),
						color: typographyStyleHeader(),
					}}
				>
					<Typography
						noWrap
						style={{
							fontSize: 18,
							fontWeight: 'bold',
							maxWidth: '95%',
						}}
					>
						{windowTitle}
					</Typography>
					{CustomHeaderComponent ? (
						<CustomHeaderComponent {...CustomHeaderComponentProps} />
					) : null}
				</Header>
			);
		}
	};

	const renderTableDataError = () => {
		if (tableData && tableData.length === 0 && !loading) {
			return (
				<Line
					data-testid="errorTable"
					style={{
						justifyContent: 'center',
						padding: 15,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay8dp
								: selectedTheme.foreground,
					}}
				>
					<Typography
						variant="subtitle2"
						style={{
							fontWeight: 'bold',
							color: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.textColorMedium,
									alternateThemeColor: '',
								},
								{ noTheme: noTheme, noThemeColor: '#000000de' }
							),
						}}
					>
						{customNoDataMessage || noDataTOvisualize}
					</Typography>
				</Line>
			);
		}
	};

	const renderTableActions = (row: any) => {
		if (typeof tableActions === 'boolean' && tableActions === true) {
			return (
				<TableCell
					align="right"
					style={{
						width: '8%',
						borderBottom: `1px solid ${HandleGetThemeColor(
							{
								themeID: 'dark',
								primaryThemeColor: selectedTheme.footerLine,
								alternateThemeColor: '#e0e0e0',
							},
							{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
						)}`,
					}}
				>
					<Line
						style={{
							justifyContent: 'center',
							marginLeft: -15,
							background: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.overlay6dp,
									alternateThemeColor: selectedTheme.foreground,
								},
								{ noTheme: noTheme, noThemeColor: '#fff' }
							),
						}}
					>
						<Box data-testid="tableAction1">
							{ActionComponent1 ? (
								<ActionComponent1 rowData={row} {...ActionComponent1Props} />
							) : null}
						</Box>
						<Box data-testid="tableAction2">
							{ActionComponent2 ? (
								<ActionComponent2 rowData={row} {...ActionComponent2Props} />
							) : null}
						</Box>
						<Box data-testid="tableAction3">
							{ActionComponent3 ? (
								<ActionComponent3 rowData={row} {...ActionComponent3Props} />
							) : null}
						</Box>
						<Box data-testid="tableAction4">
							{ActionComponent4 ? (
								<ActionComponent4 rowData={row} {...ActionComponent4Props} />
							) : null}
						</Box>
						{switches ? (
							<OfferToolTip
								title={
									row?.ISactive ? switchesToolTip?.true : switchesToolTip?.false
								}
								aria-label="tooltip"
								arrow
								TransitionComponent={Zoom}
							>
								<Box
									style={{
										opacity: userPermission ? 0.6 : '',
										marginLeft: 5,
									}}
									data-testid="tableSwitch"
									onMouseOver={() => handleSendDataSwitch(row)}
								>
									<OfferSwitch
										size="small"
										onClick={onSwitchChange || null}
										checked={row?.ISactive}
										disabled={userPermission}
										sx={{
											'& .MuiSwitch-thumb': {
												bgcolor: switchOfferStyles(row?.ISactive),
											},
											'&.Mui-checked .MuiSwitch-thumb': {
												bgcolor: switchOfferStyles(row?.ISactive),
											},
										}}
									/>
								</Box>
							</OfferToolTip>
						) : null}
					</Line>
				</TableCell>
			);
		}
		if (tableActions) {
			return (
				<TableCell
					align="right"
					style={{
						width: '8%',
						borderBottom: `1px solid ${HandleGetThemeColor(
							{
								themeID: 'dark',
								primaryThemeColor: selectedTheme.footerLine,
								alternateThemeColor: '#e0e0e0',
							},
							{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
						)}`,
					}}
				>
					<Line
						style={{
							justifyContent: 'center',
							marginLeft: -15,
							background: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.overlay6dp,
									alternateThemeColor: selectedTheme.foreground,
								},
								{ noTheme: noTheme, noThemeColor: '#fff' }
							),
							color: HandleGetThemeColor(
								{
									themeID: 'dark',
									primaryThemeColor: selectedTheme.textColorHigh,
									alternateThemeColor: selectedTheme.primary,
								},
								{ noTheme: noTheme, noThemeColor: '#fff' }
							),
						}}
					>
						{tableActions?.map((action: any, index: React.Key) => {
							const Component = action.component;
							return (
								<Box
									key={`tableAction${index}`}
									data-testid={`tableAction${index}`}
									style={{ marginLeft: index === 0 ? 0 : -10 }}
								>
									<Component rowData={row} {...action.props} />
								</Box>
							);
						})}
					</Line>
				</TableCell>
			);
		}
		return null;
	};

	const renderDataTable = () => {
		if (tableData && tableData.length > 0 && !loading) {
			sortedData = stableSort(filteredTableData, getComparator(order, orderBy));
			return (
				<Table stickyHeader size="small" aria-label="sticky table">
					<EnhancedTableHead
						classes={classes}
						numSelected={filteredTableData.length}
						order={order}
						orderBy={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={
							expansibleRows
								? filteredTableData.length + 1
								: filteredTableData.length
						}
					/>

					<TableBody>
						{sortedData
							.slice(
								page * (customRowsPerPage || rowsPerPage),
								page * (customRowsPerPage || rowsPerPage) +
									(customRowsPerPage || rowsPerPage)
							)
							.map((row: any, index) => {
								const globalIndex =
									page * (customRowsPerPage || rowsPerPage) + index;
								const labelId = `enhanced-table-checkbox-${globalIndex}`;
								const isItemSelected = isSelected(row);

								return (
									<React.Fragment key={`tableRow${globalIndex}`}>
										<TableRow
											hover
											role="checkbox"
											tabIndex={-1}
											selected={isItemSelected}
											onClick={
												clickableRows
													? () => handleSelectClick(row)
													: () => preventDefault()
											}
											style={{
												background: HandleGetThemeColor(
													{
														themeID: 'dark',
														primaryThemeColor: selectedTheme.overlay6dp,
														alternateThemeColor: selectedTheme.foreground,
													},
													{ noTheme: noTheme, noThemeColor: '#fff' }
												),
											}}
										>
											{expansibleRows && (
												<TableCell
													key={`expansible${globalIndex}`}
													style={{
														maxWidth: 40,
														borderBottom: `1px solid ${HandleGetThemeColor(
															{
																themeID: 'dark',
																primaryThemeColor: selectedTheme.footerLine,
																alternateThemeColor: '#e0e0e0',
															},
															{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
														)}`,
													}}
												>
													<IconButton
														data-testid={
															row?.isExpansible !== false
																? `openExpansibleRow${globalIndex}`
																: `disableExpansibleRow${globalIndex}`
														}
														size="small"
														disabled={row?.isExpansible === false}
														style={{
															opacity: row.isExpansible === false ? 0.6 : 1,
														}}
														id={
															row?.isExpansible !== false
																? `openExpansibleRow${globalIndex}`
																: `disableExpansibleRow${globalIndex}`
														}
														onClick={() => handleOpenRowPanel(index + 1)}
														sx={{
															'&:hover': {
																backgroundColor:
																	selectedTheme.id === 'dark' &&
																	selectedTheme.primaryLight,
															},
														}}
													>
														{openPanel[index + 1] ? (
															<KeyboardArrowUp
																data-testid={`closeExpansibleRow${index + 1}`}
																sx={{
																	color:
																		selectedTheme.id === 'dark' &&
																		selectedTheme.textColorHigh,
																}}
															/>
														) : (
															<KeyboardArrowDown
																data-testid={`openExpansibleRow${index + 1}`}
																sx={{
																	color:
																		selectedTheme.id === 'dark' &&
																		selectedTheme.textColorHigh,
																}}
															/>
														)}
													</IconButton>
												</TableCell>
											)}
											{checkboxes ? (
												<TableCell
													style={{
														maxWidth: 10,
														padding: '6px 0 6px 0',
														borderBottom: `1px solid ${HandleGetThemeColor(
															{
																themeID: 'dark',
																primaryThemeColor: selectedTheme.footerLine,
																alternateThemeColor: '#e0e0e0',
															},
															{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
														)}`,
														background:
															selectedTheme.id === 'dark' &&
															selectedTheme.overlay6dp,
													}}
												>
													<Checkbox
														size="small"
														color="primary"
														onClick={() => handleSelectClick(row)}
														checked={isItemSelected}
														inputProps={{ 'aria-labelledby': labelId }}
														style={{
															marginLeft: 17,
															color: HandleGetThemeColor(
																{
																	themeID: 'dark',
																	primaryThemeColor:
																		selectedTheme.textColorHigh,
																	alternateThemeColor: selectedTheme.primary,
																},
																{ noTheme: noTheme, noThemeColor: '#272E67' }
															),
														}}
													/>
												</TableCell>
											) : (
												<TableCell
													style={{
														padding: '0 0 0 15px',
														borderBottom: `1px solid ${HandleGetThemeColor(
															{
																themeID: 'dark',
																primaryThemeColor: selectedTheme.footerLine,
																alternateThemeColor: '#e0e0e0',
															},
															{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
														)}`,
													}}
												/>
											)}
											{colsData?.map((col, colIndex) => (
												<TableCell
													key={`tableRowCell-${globalIndex}-${colIndex}`}
													component="th"
													id={labelId}
													scope="row"
													align={colIndex === 0 ? 'left' : 'center'}
													style={{
														maxWidth: col.cellWidth,
														color: HandleGetThemeColor(
															{
																themeID: 'dark',
																primaryThemeColor: selectedTheme.textColorHigh,
																alternateThemeColor: '',
															},
															{ noTheme: noTheme, noThemeColor: '#202020' }
														),
														borderBottom: `1px solid ${HandleGetThemeColor(
															{
																themeID: 'dark',
																primaryThemeColor: selectedTheme.footerLine,
																alternateThemeColor: '#e0e0e0',
															},
															{ noTheme: noTheme, noThemeColor: '#e0e0e0' }
														)}`,
													}}
												>
													<Box
														width="100%"
														overflow="hidden"
														textOverflow="ellipsis"
														whiteSpace="nowrap"
														style={{
															marginLeft: colIndex !== 0 ? -10 : 0,
														}}
													>
														<DataTypes
															denseText={denseText}
															row={row}
															col={col}
														/>
													</Box>
												</TableCell>
											))}
											{renderTableActions(row)}
										</TableRow>
										{expansibleRows && (
											<ExpansibleTableRow
												expandedTableData={expandedTableData}
												index={index + 1}
												expandedColsData={expandedColsData}
												expandedDataFetch={expandedDataFetch}
												expandedTableActions={expandedTableActions}
												noDataMessage={expandedNoDataMessage}
												rowData={row}
												colSpan={colsData.length + 3}
											/>
										)}
									</React.Fragment>
								);
							})}
					</TableBody>
				</Table>
			);
		}
	};

	const renderLoading = () => {
		if (loading) {
			return (
				<div
					data-testid="loadingTable"
					style={{
						padding: '10px 20px 10px',
						backgroundColor:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay4dp
								: selectedTheme.background,
					}}
				>
					<Skeleton
						width="100%"
						count={customRowsPerPage || rowsPerPage}
						height={35}
						baseColor={
							selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#e7e7e7'
						}
						highlightColor={
							selectedTheme.id === 'dark' && selectedTheme.textColorDisable
						}
					/>
				</div>
			);
		}
	};

	return (
		<Card className={classes.root} key={tableKey || 'cardTable'}>
			{backdropLoading && <BackDropLoading smallBackdrop />}
			{renderHeader()}
			<Paper
				sx={{
					'.MuiTableContainer-root': {
						background: HandleGetThemeColor(
							{
								themeID: 'dark',
								primaryThemeColor: selectedTheme.overlay6dp,
								alternateThemeColor: '#fff',
							},
							{ noTheme: noTheme, noThemeColor: '#fff' }
						),
					},
					width: nestedTable ? '86vw' : '100%',
				}}
			>
				{renderHeaderLine()}
				{renderTableDataError()}
				<TableContainer className={classes.container}>
					{renderDataTable()}
					{renderLoading()}
				</TableContainer>
			</Paper>
			{renderFooter()}
		</Card>
	);
};

export default OfferTable;
