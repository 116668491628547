import React, { useEffect, useState } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { api, setupRequestToken } from '../../utils/api';
import { ReactiveBase, ReactiveList } from '@appbaseio/reactivesearch';
import { useAuth0 } from '../../react-auth0-spa';
import { useTranslation } from 'react-i18next';
import {
	IconButton,
	MenuItem,
	Select,
	Snackbar,
	Typography,
} from '@mui/material';
import Card from './Card';
import {
	AddCircle,
	CloseRounded,
	PlaylistAddRounded,
} from '@mui/icons-material/';
import LoadingDots from '../../components/LoadingDots';
import Unauthorized from '../Page401';
import { CardContainer, Column, Line } from '../../styles';
import RenderPagination from '../../components/RenderPagination';
import { usePO } from '../../utils/POContext';
import { useCardSelector } from '../../hooks/Classificacoes/CardSelector';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveMenu } from '../../redux/slices/menuSlice';
import { changePanelState } from '../../redux/slices/panelControl';
import BasicSearchHeader from '../../components/BasicSearchHeader';
import FilterBarComponent from '../../components/FilterBar';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getAuthorByElasticSearch } from '../../services/authors';
import AuthorDetailsModal from './AuthorModal';
import RadialMenu from '../../components/RadialMenu';
import InsertListModal from './InsertListModal';

const SearchResults = () => {
	const { authorID } = useParams<{ authorID: string }>();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const { selectedTheme, selectedClient, userData } = usePO();
	const { token } = useAuth0();
	const { selectedCards, handleSelectCard } = useCardSelector();
	const dispatch = useDispatch();
	const panelControlList = useSelector((state: any) => state.panelControl);
	setupRequestToken(api, token);
	const bearerToken = `Bearer ${token}`;
	const elasticProxyUrl = `${process.env.REACT_APP_API_BASE_URL}/authors`;

	const [resultsPerPage, setResultsPerPage] = useState(20);
	const [numberOfResults, setNumberOfResults] = useState(0);
	const [resultsMessage, setResultMessages] = useState('');
	const [newKey, setNewKey] = useState(0);
	const [opemModal, setOpenModal] = React.useState(false);
	const [authorData, setAuthorData] = React.useState<any>({});
	const [openInsertListModal, setOpenInsertListModal] = useState(false);

	React.useEffect(() => {
		if (authorID !== undefined && authorID !== '') {
			fetchOfferByElasticSearch(authorID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorID]);

	const fetchOfferByElasticSearch = async (id: string) => {
		const query = `{"preference":"ReactiveListResult"}\n{"query":{"bool":{"must":[{"match":{"authorID": "${id}"}}]}},"size": 20}\n`;
		const response: any = await getAuthorByElasticSearch(query);
		if (response.data.responses[0].hits.total.value > 0) {
			setAuthorData(response.data.responses[0].hits.hits[0]._source);
			setOpenModal(true);
		}
		if (response.data.responses[0].hits.total.value === 0) {
			enqueueSnackbar(t('Autores.Autor não encontrado'), { variant: 'error' });
		}
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	useEffect(() => {
		dispatch(changeActiveMenu('autores'));
	}, [dispatch]);

	const getNewKey = () => {
		setNewKey(newKey + 1);
	};

	const defaultQuery = () => {
		return {
			query: {
				bool: {
					must: [
						{
							exists: {
								field: 'authorName',
							},
						},
					],
				},
			},
			size: 0,
		};
	};

	const standardQuery = () => {
		return {
			query: { match_all: {} },
		};
	};

	const renderResultsPerPage = () => (
		<Select
			labelId="results-per-page"
			disableUnderline
			id="results-select"
			value={resultsPerPage}
			onChange={(event) => setResultsPerPage(event.target.value as number)}
			style={{
				border:
					selectedTheme.id === 'dark'
						? `1px solid ${selectedTheme.footerLine}`
						: `1px solid #dedede`,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
			}}
			sx={{
				'.MuiSelect-select': {
					padding: '5px 10px',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay3dp
							: selectedTheme.foreground,
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					'&:hover': {
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					},
				},
				'.MuiSelect-icon': {
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				},
				'& [aria-expanded=true]': {
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
				},
			}}
			inputProps={{
				MenuProps: {
					PaperProps: {
						sx: {
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.overlay3dp
									: selectedTheme.foreground,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						},
					},
				},
			}}
		>
			<MenuItem value={10}>10</MenuItem>
			<MenuItem value={20}>20</MenuItem>
			<MenuItem value={50}>50</MenuItem>
		</Select>
	);

	const handleChangePanelState = () => {
		if (panelControlList.panelControl === '') {
			dispatch(changePanelState('authorsPanelControl'));
		} else {
			dispatch(changePanelState(''));
		}
	};

	const renderHeader = () => (
		<>
			<Line
				style={{
					height: 42,
					justifyContent: 'space-between',
					margin: '0 10px 0 10px',
					width: '80%',
					position: 'absolute',
					top: 10,
				}}
			>
				<Line style={{ justifyContent: 'flex-start', width: '25%', gap: 10 }}>
					<Typography
						noWrap
						variant="subtitle2"
						style={{
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Autores.Itens por página')}:
					</Typography>
					{renderResultsPerPage()}
				</Line>
				<Line style={{ justifyContent: 'flex-start', width: '25%' }}>
					{numberOfResults > 0 ? (
						<Typography
							noWrap
							variant="caption"
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{`${t('Autores.Resultados')}:  ${numberOfResults?.toLocaleString(
								userData.i18nID
							)}`}
						</Typography>
					) : (
						<Typography
							noWrap
							variant="caption"
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{resultsMessage}
						</Typography>
					)}
				</Line>
				<Line style={{ justifyContent: 'flex-start', width: '50%' }}>
					<BasicSearchHeader />
					<Line style={{ justifyContent: 'flex-start' }}>
						<IconButton
							size="small"
							style={{
								borderRadius: 4,
								cursor: 'pointer',
							}}
							onClick={handleChangePanelState}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<PlaylistAddRounded
								fontSize="small"
								style={{
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							/>
							<Typography
								noWrap
								variant="caption"
								align="left"
								data-testid="addFilters"
								style={{
									marginLeft: 4,
									marginBottom: -2,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorMedium
											: '',
								}}
							>
								{t('Autores.Adicionar filtros')}
							</Typography>
						</IconButton>
					</Line>
				</Line>
			</Line>
		</>
	);

	const renderResults = (data: any) => {
		return (
			<Line>
				<CardContainer>
					{data.map((el) => (
						<Card authorData={el} margin={8} key={el.authorID} />
					))}
				</CardContainer>
			</Line>
		);
	};

	const menuItem1Icon = (
		<AddCircle
			style={{
				fontSize: 24,
				marginTop: -1,
				marginRight: -0.5,
				color:
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.foreground,
			}}
		/>
	);

	const handleOpenInsertList = () => {
		setOpenInsertListModal(!openInsertListModal);
	};

	const sortOptions = [
		{
			label: `${t('Autores.Mais relevantes')}`,
			dataField: '_score',
			sortBy: 'desc',
		},
		{
			label: `${t('Autores.Nome')} ▴`,
			dataField: 'authorName',
			sortBy: 'asc',
		},
		{
			label: `${t('Autores.Nome')} ▾`,
			dataField: 'authorName',
			sortBy: 'desc',
		},
		{
			label: `${t('Classificações.Mais recente')}`,
			dataField: 'created',
			sortBy: 'desc',
		},
		{
			label: `${t('Classificações.Mais antigo')}`,
			dataField: 'created',
			sortBy: 'asc',
		},
		{
			label: `${t('Classificações.Atualizados recentemente')}`,
			dataField: 'updated',
			sortBy: 'desc',
		},
	];

	const menuData = [
		{
			icon: menuItem1Icon,
			action: handleOpenInsertList,
			tooltip: 'Autores.Inserir lista de ids para classificação',
		},
	];

	return userData.permissions?.indexOf('read:authors') !== -1 ? (
		<div>
			<AuthorDetailsModal
				authorData={authorData}
				open={opemModal}
				handleClose={handleCloseModal}
			/>
			<RadialMenu menuData={menuData}></RadialMenu>
			<ReactiveBase
				key={newKey}
				app="elasticsearch"
				url={elasticProxyUrl}
				headers={{
					Authorization: bearerToken,
				}}
				theme={{
					typography: {
						fontFamily: 'Raleway, Helvetica, sans-serif',
					},
					colors: {
						primaryColor: selectedTheme.primaryDark,
						titleColor: '#505050',
					},
				}}
			>
				{renderHeader()}
				<Line style={{ alignItems: 'flex-start', height: '100%' }}>
					<Column
						style={{
							height: '100%',
							width:
								panelControlList.panelControl === 'authorsPanelControl'
									? 340
									: 0,
							transition: 'width 0.5s',
						}}
					>
						<FilterBarComponent
							nameSearchField="authorName"
							idAuthorSearchField="authorID"
							platformSearchField="platformINcountryID"
							followersSearchField="authorData.followers.value"
							followers
							citySearchField="cityName"
							countrySearchField="countryName"
							stateSearchField="stateName"
						/>
					</Column>
					<Column style={{ height: '100%', minWidth: '70%' }}>
						<ReactiveList
							key={newKey}
							componentId="ReactiveListResult"
							defaultQuery={
								selectedClient !== null ? defaultQuery : standardQuery
							}
							dataField="*"
							size={resultsPerPage}
							pagination
							renderPagination={({
								pages,
								totalPages,
								currentPage,
								setPage,
							}) => (
								<RenderPagination
									pages={pages}
									totalPages={totalPages}
									currentPage={currentPage}
									setPage={setPage}
								/>
							)}
							sortOptions={sortOptions}
							stream={false}
							react={{
								and: [
									'idAuthorSearch',
									'citySearch',
									'countrySearch',
									'nameSearch',
									'platformSearch',
									'searchbox',
									'stateSearch',
									'authorData.followers.value',
								],
							}}
							style={{
								margin: 5,
								width: '100%',
							}}
							renderResultStats={(stats) => {
								setNumberOfResults(stats.numberOfResults);
								return null;
							}}
							renderNoResults={() => {
								setNumberOfResults(0);
								setResultMessages(t('Autores.Nenhum resultado encontrado'));
								return <div style={{ height: '120vh' }} />;
							}}
							render={({ loading, error, data }) => {
								if (loading) {
									setResultMessages('');
									setNumberOfResults(0);
									return (
										<Column
											data-testid="loadingCards"
											style={{
												height: '100vh',
												width: '100%',
												justifyContent: 'center',
												alignContent: 'center',
												marginTop: '-50px',
												marginBottom: 300,
											}}
										>
											<LoadingDots width={120} height={70} key="loading" loop />

											<Typography
												variant="subtitle2"
												style={{
													marginTop: '-10px',
													fontWeight: 'bold',
													color:
														selectedTheme.id === 'dark'
															? selectedTheme.textColorHigh
															: selectedTheme.primary,
												}}
											>
												{t('Autores.Carregando resultados')}
											</Typography>
										</Column>
									);
								}
								if (error) {
									setResultMessages('');
									setTimeout(() => {
										getNewKey();
									}, 10000);
									return (
										<Column
											style={{
												height: '100vh',
												width: '100%',
												justifyContent: 'center',
												alignContent: 'center',
												marginTop: '-50px',
												marginBottom: 300,
											}}
										>
											<LoadingDots width={150} height={80} key="loading" loop />
											<Typography
												variant="subtitle2"
												style={{
													fontWeight: 'bold',
													color:
														selectedTheme.id === 'dark'
															? selectedTheme.primary
															: '',
												}}
											>
												{t('Autores.Houve um erro ao processar a requisição')}.
											</Typography>
											<Typography
												variant="subtitle2"
												style={{
													fontWeight: 'bold',
													color:
														selectedTheme.id === 'dark'
															? selectedTheme.primary
															: '',
												}}
											>
												{t(
													'Autores.Estamos recarregando a busca. Aguarde por favor.'
												)}
											</Typography>
										</Column>
									);
								}
								return renderResults(data);
							}}
						/>
					</Column>
				</Line>
			</ReactiveBase>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={selectedCards.length > 0}
				message={`${t('Autores.Itens selecionados')}: ${selectedCards.length}`}
				action={
					<>
						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={() => handleSelectCard(null, 'clear')}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<CloseRounded fontSize="small" />
						</IconButton>
					</>
				}
			/>
			<InsertListModal
				handleToggleModal={handleOpenInsertList}
				open={openInsertListModal}
			/>
		</div>
	) : (
		<div style={{ marginLeft: '-75px' }}>
			<Unauthorized />
		</div>
	);
};

export default SearchResults;
