import React, { useState } from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Checkbox,
	FormControlLabel,
	IconButton,
} from '@mui/material';
import { Error, CloseRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { api, setupRequestToken } from '../../../utils/api';
import Loading from '../../../components/LoadingDots';
import { useAuth0 } from '../../../react-auth0-spa';
import { usePO } from '../../../utils/POContext';
import { Column, Line } from '../../../styles';
import SubmitedResumeDialog from '../SubmitedResumeDialog';
import { useSnackbar } from 'notistack';
import InsertListPopover from './InsertListPopover';
import {
	updateAuthorInvestigation,
	updateAuthorOfficial,
} from '../../../services/authors/bulkActions';

interface Props {
	handleToggleModal: () => void;
	open: boolean;
}

const InsertListModal: React.FC<Props> = ({ handleToggleModal, open }) => {
	const { token } = useAuth0();
	const { t } = useTranslation();
	const { selectedClient, selectedTheme } = usePO();
	const { enqueueSnackbar } = useSnackbar();

	setupRequestToken(api, token);

	const [formattedItems, setFormattedItems] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [openResumeDialog, setOpenResumeDialog] = useState(false);
	const [submitDataResults, setSubmitDataResults] = useState<any>();
	const [formError, setFormError] = useState<string[]>([]);
	const [checkBoxesState, setCheckboxesState] = useState({
		checkedOfficial: false,
		checkedInvestigation: false,
	});
	const [progress, setProgress] = useState(0);
	const [buffer, setBuffer] = useState(10);
	const [successIDs, setSuccessIDs] = useState<string[]>([]);
	const [failedIDs, setFailedIDs] = useState<string[]>([]);

	const handleClose = () => {
		handleToggleModal();
		setFormattedItems([]);
		setFormError([]);
		setCheckboxesState({
			checkedOfficial: false,
			checkedInvestigation: false,
		});
	};

	const handleCloseResumeDialog = () => setOpenResumeDialog(false);

	const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckboxesState({
			...checkBoxesState,
			[event.target.name]: event.target.checked,
		});

		if (event.target.name === 'checkedOfficial' && event.target.checked) {
			setCheckboxesState((prevState) => ({
				...prevState,
				checkedInvestigation: false,
			}));
		} else if (
			event.target.name === 'checkedInvestigation' &&
			event.target.checked
		) {
			setCheckboxesState((prevState) => ({
				...prevState,
				checkedOfficial: false,
			}));
		}
	};

	const handleUpdateInvestigation = async (
		formattedItems,
		investigationStatus
	) => {
		try {
			const response = await updateAuthorInvestigation(
				formattedItems,
				investigationStatus
			);

			return response;
		} catch (error) {
			enqueueSnackbar(
				t('Autores.Erro ao atualizar autores como investigação.'),
				{
					variant: 'error',
				}
			);
			throw error;
		}
	};

	const handleUpdateOfficial = async (formattedItems, officialStatus) => {
		try {
			const response = await updateAuthorOfficial(
				formattedItems,
				officialStatus
			);

			return response;
		} catch (error) {
			enqueueSnackbar(t('Autores.Erro ao atualizar autores como oficiais.'), {
				variant: 'error',
			});
			throw error;
		}
	};

	const submitList = async () => {
		if (formattedItems.length === 0) {
			setFormError((formError) => [...formError, 'IDlist']);
			enqueueSnackbar(t('Autores.Insira uma lista de IDs para processamento'), {
				variant: 'warning',
			});
			return;
		}

		setLoading(true);
		setProgress(0);
		setBuffer(10);

		handleClose();
		setOpenResumeDialog(true);

		const successes: string[] = [];
		const failures: string[] = [];
		const successDetails: string[] = [];
		const failureDetails: string[] = [];
		let officialSuccess = 0;
		let investigationSuccess = 0;

		try {
			const totalItems = formattedItems.length;
			let completed = 0;

			const processItem = async (item) => {
				let itemSuccess = false;

				if (checkBoxesState.checkedOfficial) {
					const results = await handleUpdateOfficial(
						[item],
						checkBoxesState.checkedOfficial
					);
					const success = results.some((res) => res.success);

					if (success) {
						officialSuccess++;
						itemSuccess = true;
						successDetails.push(results.map((res) => res.message).join(', '));
					} else {
						failureDetails.push(results.map((res) => res.message).join(', '));
					}
				}

				if (checkBoxesState.checkedInvestigation) {
					const results = await handleUpdateInvestigation(
						[item],
						checkBoxesState.checkedInvestigation
					);
					const success = results.some((res) => res.success);

					if (success) {
						investigationSuccess++;
						itemSuccess = true;
						successDetails.push(results.map((res) => res.message).join(', '));
					} else {
						failureDetails.push(results.map((res) => res.message).join(', '));
					}
				}

				if (itemSuccess) {
					successes.push(item);
				} else {
					failures.push(item);
				}

				completed++;
				setProgress((completed / totalItems) * 100);
				setBuffer((completed / totalItems) * 100 + 10);
			};

			for (const item of formattedItems) {
				try {
					await processItem(item);
				} catch (error) {
					failures.push(item);
					failureDetails.push(
						`Erro ao atualizar ID ${item}: ${'Erro desconhecido'}`
					);
				}
			}

			setSuccessIDs(successes);
			setFailedIDs(failures);
			setSubmitDataResults({ successDetails, failureDetails });

			if (successes.length > 0) {
				let successMessage = '';

				if (officialSuccess > 0) {
					successMessage = `${officialSuccess} ${t('Autores.ID(s) foram classificados como oficiais.')}`;
				}

				if (investigationSuccess > 0) {
					if (successMessage) {
						successMessage += ' ';
					}
					successMessage += `${investigationSuccess} ${t('Autores.ID(s) foram atualizados como sob investigação.')}`;
				}

				enqueueSnackbar(successMessage, { variant: 'success' });
			}

			if (failures.length > 0) {
				enqueueSnackbar(
					`${failures.length} ${t('Autores.ID(s) falharam ao atualizar.')}`,
					{ variant: 'error' }
				);
			}
		} catch (error) {
			enqueueSnackbar(t('Autores.Erro ao enviar a lista.'), {
				variant: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	const renderBulkTypeSelector = () => (
		<Line
			style={{ marginTop: 10, marginLeft: 20, justifyContent: 'flex-start' }}
		>
			<FormControlLabel
				control={
					<Checkbox
						checked={checkBoxesState.checkedOfficial}
						onChange={handleChangeChecked}
						name="checkedOfficial"
						disabled={checkBoxesState.checkedInvestigation}
						style={{
							color: checkBoxesState.checkedInvestigation
								? selectedTheme.textColorDisable
								: selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					/>
				}
				label={
					<Typography
						style={{
							color: checkBoxesState.checkedInvestigation
								? selectedTheme.textColorDisable
								: selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: undefined,
						}}
					>
						{t('Autores.Permitir')}
					</Typography>
				}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={checkBoxesState.checkedInvestigation}
						onChange={handleChangeChecked}
						name="checkedInvestigation"
						disabled={checkBoxesState.checkedOfficial}
						style={{
							color: checkBoxesState.checkedOfficial
								? selectedTheme.textColorDisable
								: selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					/>
				}
				label={
					<Typography
						style={{
							color: checkBoxesState.checkedOfficial
								? selectedTheme.textColorDisable
								: selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: undefined,
						}}
					>
						{t('Autores.Investigação')}
					</Typography>
				}
			/>
		</Line>
	);

	const renderWarning = () => (
		<Line
			style={{
				width: '100%',
				background: selectedTheme.background,
				justifyContent: 'center',
				opacity: 0.7,
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
				padding: '10px 15px',
			}}
		>
			<Error style={{ fontSize: 30, color: 'orange' }} />
			<Typography
				variant="caption"
				sx={{
					width: '70%',
					marginLeft: '20px',
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
				}}
			>
				{t(
					`Autores.Durante o processamento de múltiplos ids o sistema ficará em espera. Por favor, aguarde.`
				)}
			</Typography>
		</Line>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				border: `1px solid ${
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#f2f2f2'
				}`,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme.foreground,
				padding: 10,
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					margin: '2px 10px 0 10px',
				}}
			>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Autores.Cancelar')}
				</Button>
				{loading ? (
					<Loading width={60} height={30} loop />
				) : (
					<Button
						size="small"
						onClick={submitList}
						disabled={
							loading ||
							(checkBoxesState.checkedOfficial === false &&
								checkBoxesState.checkedInvestigation === false &&
								formattedItems.length === 0)
						}
						style={{
							color:
								loading ||
								(checkBoxesState.checkedOfficial === false &&
									checkBoxesState.checkedInvestigation === false &&
									formattedItems.length === 0)
									? '#c4c4c4'
									: selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.primaryDark
									: selectedTheme.primaryDark,
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
						}}
					>
						{t('Autores.Aplicar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	const renderHeader = () => (
		<DialogTitle
			style={{
				display: 'flex',
				height: 60,
				borderBottom: 'solid',
				borderBottomWidth: 1,
				borderBottomColor:
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
				padding: '12px 20px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.tableHead
						: selectedTheme.gradient,
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Typography
					variant="h6"
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
					}}
				>
					{t('Autores.Operações em lote por lista de IDs')}
				</Typography>
				<IconButton
					onClick={handleClose}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						sx={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primary,
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderClient = () => (
		<Line style={{ width: '100%', justifyContent: 'flex-end' }}>
			<Typography
				variant="subtitle1"
				style={{
					margin: '15px 5px',
					opacity: 0.9,
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{`${t('Autores.Cliente')}: ${selectedClient?.name}`}
			</Typography>
		</Line>
	);

	return (
		<div>
			<Dialog
				open={open}
				maxWidth="sm"
				fullWidth
				onClose={(reason) => {
					if (reason !== 'backdropClick') {
						handleClose();
					}
				}}
			>
				{renderHeader()}
				<DialogContent
					style={{
						padding: 0,
						background: selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
					}}
				>
					<Column style={{ padding: '20px 30px 2px 30px' }}>
						<InsertListPopover
							formError={formError}
							formattedItems={formattedItems}
							setFormattedItems={setFormattedItems}
						/>
						{renderBulkTypeSelector()}
						{renderClient()}
					</Column>
					{renderWarning()}
				</DialogContent>
				{renderFooter()}
			</Dialog>

			<SubmitedResumeDialog
				open={openResumeDialog}
				close={handleCloseResumeDialog}
				data={submitDataResults}
				loading={loading}
				progress={progress}
				buffer={buffer}
				successIDs={successIDs}
				failedIDs={failedIDs}
			/>
		</div>
	);
};

export default InsertListModal;
