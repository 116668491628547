import React, { useState } from 'react';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../../utils/POContext';
import { Column, Line } from '../../../styles';
import Panel from '../../../components/Panel';
import { useCardSelector } from '../../../hooks/Classificacoes/CardSelector';

interface Props {
	data: any;
	open: boolean;
	close: () => void;
}

const SubmitedResumeDialog: React.FC<Props> = ({
	data,
	open,
	close,
}: Props) => {
	const { handleSelectCard } = useCardSelector();
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const [keepSelected, setKeepSelected] = useState(false);

	const handleSelect = (id) => {
		handleSelectCard({ offerID: id }, 'add');
	};

	const handleClose = () => {
		if (keepSelected) {
			handleSelectCard(null, 'clear');
			data?.notGood.map((el) => handleSelect(el.id));
			close();
		} else {
			handleSelectCard(null, 'clear');
			close();
		}
	};

	const handleChangeCheckbox = (event) => {
		setKeepSelected(event.target.checked);
	};

	const renderNotGoodMessages = () =>
		data?.good.length > 0
			? `${data?.notGood.length} ${t('Classificações.de')} ${
					data?.good.length + data?.notGood.length
				} ${t('Classificações.anúncios não puderam ser classificados')}:`
			: `${data?.notGood.length} ${t(
					'Classificações.anúncios não puderam ser classificados'
				)}:`;

	const renderDialogTitle = () => (
		<Line style={{ justifyContent: 'space-between', alignItems: 'center' }}>
			<Typography
				variant="h6"
				style={{
					color: selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
				}}
			>
				{data?.notGood.length === 0
					? data.good.length + t('Classificações. anúncios foram classificados')
					: renderNotGoodMessages()}
			</Typography>
			<IconButton
				size="small"
				onClick={close}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Close
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
					}}
				/>
			</IconButton>
		</Line>
	);

	const rendedrNotGoodResults = () =>
		data?.notGood.map((el, index) => (
			<Panel
				key={el.id}
				title={el.title}
				noMargin={data?.notGood.length === index + 1}
				contentPadding="10px 20px 5px 45px"
			>
				<Column style={{ width: '100%', alignItems: 'flex-start' }}>
					<Typography
						variant="caption"
						style={{
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
						data-testid={`offer${index + 1}_ID`}
					>
						{t('Classificações.ID da oferta')}: {el.id}
					</Typography>
					<Typography variant="caption" style={{ color: selectedTheme.error }}>
						{t('Classificações.Motivo')}: {el.detail}
					</Typography>
				</Column>
			</Panel>
		));

	const rendedrGoodResults = () =>
		data?.good.map((el, index) => (
			<Panel
				key={el.id}
				title={el.title}
				noMargin={data?.notGood.length === index + 1}
				contentPadding="10px 20px 5px 45px"
			>
				<Column style={{ width: '100%', alignItems: 'flex-start' }}>
					<Typography variant="caption">
						{t('Classificações.ID da oferta')}: {el.id}
					</Typography>
					<Typography
						variant="caption"
						style={{ color: selectedTheme.success }}
					>
						{t('Classificações.Mensagem')}: {el.detail}
					</Typography>
				</Column>
			</Panel>
		));

	return (
		<Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
			<DialogTitle
				style={{
					display: 'flex',
					borderBottom: 'solid',
					borderBottomWidth: 1,
					borderBottomColor:
						selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
					padding: '12px 20px',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
				}}
			>
				{renderDialogTitle()}
			</DialogTitle>
			<DialogContent
				style={{
					padding: 0,
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
				}}
			>
				{data?.notGood.length > 0 ? (
					<Panel
						open={data?.good.length === 0}
						title={t('Classificações.Anúncios não classificados')}
						contentPadding="3px 20px 0px 20px"
					>
						<Column style={{ alignItems: 'flex-start' }}>
							{rendedrNotGoodResults()}
						</Column>
					</Panel>
				) : null}
				{data?.good.length > 0 ? (
					<Panel
						open={data?.notGood.length === 0}
						title={t('Classificações.Anúncios classificados com sucesso')}
						contentPadding="3px 20px 0px 20px"
					>
						<Column style={{ alignItems: 'flex-start' }}>
							{rendedrGoodResults()}
						</Column>
					</Panel>
				) : null}
			</DialogContent>
			<DialogActions
				style={{
					borderTop: 'solid',
					borderTopWidth: 1,
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.overlay3dp
							: selectedTheme.foreground,
				}}
			>
				<Line
					style={{
						justifyContent:
							data?.notGood.length > 0 ? 'space-between' : 'flex-end',
						margin: '2px 10px 0px 10px',
						columnGap: 20,
					}}
				>
					{data?.notGood.length > 0 ? (
						<FormControlLabel
							control={
								<Checkbox
									checked={keepSelected}
									onChange={handleChangeCheckbox}
									size="small"
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: selectedTheme.primaryDark,
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								/>
							}
							label={
								<Typography
									variant="subtitle2"
									style={{
										marginBottom: -2,
										color:
											selectedTheme.tone === 'dark' &&
											selectedTheme.textColorHigh,
									}}
								>
									{t('Classificações.Manter seleção dos anúncios falhados')}
								</Typography>
							}
							style={{ marginTop: -3, marginLeft: -5 }}
						/>
					) : null}

					<Button
						data-testid="closeButton"
						size="small"
						onClick={handleClose}
						style={{
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
							color:
								selectedTheme.tone === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.primaryDark
									: selectedTheme.primaryDark,
							width: 'auto',
						}}
					>
						{t('Classificações.Fechar')}
					</Button>
				</Line>
			</DialogActions>
		</Dialog>
	);
};

export default SubmitedResumeDialog;
